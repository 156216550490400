







import { Vue, Component, Prop } from 'vue-property-decorator';
import Card from '@/shared/resources/components/cards/Card.vue';
import DocumentsMinTable from '@/app/modules/documents/components/partials/tables/DocumentsMinTable.vue';
import ApiORMQueryBuilderExtended from '@/shared/lib/api/query-builders/ApiORMQueryBuilderExtended';
import DocumentModel from '@/app/modules/documents/models/DocumentModel';
import DocumentTypesEnum from '@/modules/documents/enums/DocumentTypesEnum';

@Component({
  components: {
    DocumentsMinTable,
    Card,
  },
})
export default class LastDocumentsList extends Vue {
  /**
   * Props
   */
  @Prop({ default: 5 }) private limit!: number;

  /**
   * Getters
   */
  private get query(): ApiORMQueryBuilderExtended {
    return DocumentModel
      .apiExt()
      .where('types', [DocumentTypesEnum.SALE_INVOICE]);
  }
}
