







import { Component, Prop, Vue } from 'vue-property-decorator';
import ApiORMQueryBuilderExtended from '@/shared/lib/api/query-builders/ApiORMQueryBuilderExtended';
import OnlineOrderCoreModel from '@/modules/online-orders/models/OnlineOrderCoreModel';
import OnlineOrdersMinTable from '@/app/modules/online-orders/components/partials/tables/OnlineOrdersMinTable.vue';

@Component({
  components: {
    OnlineOrdersMinTable,
  },
})
export default class LastOnlineOrdersList extends Vue {
  /**
   * Props
   */
  @Prop({ default: 5 }) private limit!: number;

  /**
   * Getters
   */
  private get query(): ApiORMQueryBuilderExtended {
    return OnlineOrderCoreModel
      .apiExt()
      .with('statuses');
  }
}
