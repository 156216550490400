































































































































import { Component, Vue } from 'vue-property-decorator';

import Card from '@/shared/resources/components/cards/Card.vue';
import MainHeader from '@/shared/resources/components/MainHeader.vue';
import CardGroup from '@/shared/resources/components/cards/CardGroup.vue';
import Button from '@/shared/resources/components/buttons/Button.vue';
import Header from '@/shared/resources/components/Header.vue';
import GridRow from '@/shared/resources/components/grid/GridRow.vue';
import GridCol from '@/shared/resources/components/grid/GridCol.vue';
import LastDocumentsList from '@/app/modules/documents/components/widgets/LastDocumentsList.vue';
import Icon from '@/shared/resources/components/Icon.vue';
import DashboardShortcutButton from '@/app/components/dashboard/DashboardShortcutButton.vue';
import CardIconInfo from '@/shared/resources/components/cards/CardIconInfo.vue';
import LastOnlineOrdersList from '@/app/modules/online-orders/widgets/LastOnlineOrdersList.vue';
import Loader from '@/shared/resources/components/Loader.vue';
import ProductCoreModel from '@/modules/products/models/ProductCoreModel';
import CategoryCoreModel from '@/modules/categories/models/CategoryCoreModel';
import CategoryTypesEnum from '@/modules/categories/enums/CategoryTypesEnum';
import OnlineOrderCoreModel from '@/modules/online-orders/models/OnlineOrderCoreModel';
import DocumentTypesEnum from '@/modules/documents/enums/DocumentTypesEnum';
import DocumentModel from '@/app/modules/documents/models/DocumentModel';
import TrainingBanner from '@/app/components/dashboard/TrainingBanner.vue';

@Component({
  components: {
    TrainingBanner,
    Loader,
    LastOnlineOrdersList,
    CardIconInfo,
    DashboardShortcutButton,
    Icon,
    LastDocumentsList,
    GridCol,
    GridRow,
    Header,
    Button,
    CardGroup,
    MainHeader,
    Card,
  },
})
export default class DashboardView extends Vue {
  /**
   * Data
   */
  private statsQueries: object = {
    products: () => ProductCoreModel
        .apiExt()
        .where('online', true)
        .count(),

    categories: () => CategoryCoreModel
        .apiExt()
        .where('online', true)
        .where('types', [CategoryTypesEnum.OCCASION, CategoryTypesEnum.PRODUCTS])
        .count(),

    onlineOrders: () => OnlineOrderCoreModel
        .apiExt()
        .count(),

    saleDocuments: () => DocumentModel
        .apiExt()
        .where('types', [DocumentTypesEnum.SALE_INVOICE])
        .count(),
  };

  /**
   * Display getters
   */
  private get displayStats(): boolean {
    return this.$vuetify.breakpoint.mdAndUp;
  }
}
