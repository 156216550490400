










import {Vue, Component, Prop} from 'vue-property-decorator';

@Component
export default class CardGroup extends Vue {
  @Prop({default: 2}) private columns!: number;
}
